<!-- =========================================================================================
    File Name: Invoice.vue
    Description: Invoice Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="invoice-page">
  
        <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center">
            <!--<vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button>-->
            <vs-button :to="{ name: 'treatments'}" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
          </div>
          <div class="flex items-center">
            <!--<vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button>-->
            <vs-button color="success" type="filled" class="mb-base m-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Imprimer</vs-button>
          </div>
        </div>
  
        <vx-card id="invoice-container">
            <!-- INVOICE METADATA -->
            <div class="vx-row leading-loose p-base">
                <div class="vx-col w-1/2 mt-base">
                  <div class="vx-col w-1/4 invoice__recipient-info">
                     <!--<h5>POLYCLINIQUE INTERNATIONALE SAINT JOSEPH DE LOME</h5>-->
                    <h5>POLYCLINIQUE</h5>
                    </div>
                    <img src="@/assets/images/logo/logoprescription.png" alt="vuexy-logo">
                </div>
                <div class="vx-col w-1/2 text-right">
                    <h1>TRAITEMENT</h1>
                    <div class="invoice__invoice-detail mt-6">
                       <!--<h6>N° TRAITEMENT</h6>
                        <p>085AZ04</p>-->
  
                        <h6 class="mt-4">DATE</h6>
                        <p>{{ invoice_data.created_at | date_time }}</p>
                    </div>
                </div>
                <div class="vx-col w-1/2 mt-base text-left mt-12">
                    <h5>INFORMATION SUR LE PATIENT</h5>
                    <b></b>
                    <div class="invoice__recipient-info my-4">
                      <p>NOM & PRÉNOM(S) : <b>{{invoice_data.patient.last_name}}{{'  '}}{{invoice_data.patient.first_name}}</b></p>
                      <p>TÉLÉPHONE : <b>{{ invoice_data.patient.phoneNumber }}</b></p>
                    </div>
                </div>
                <div class="vx-col w-1/2 mt-base text-right mt-12">
                    <h5>INFORMATION SUR LE DOCTEUR</h5>
                    <div class="invoice__company-info my-4">
                      <p>NOM : Dr. <b>{{invoice_data.author.last_name}}{{'  '}}{{invoice_data.author.first_name}}</b></p>
                      <p>SPÉCIALITÉ : <b>{{ roleObject[ invoice_data.author.role.name] }}</b></p>
                      <p>TÉLÉPHONE : <b>{{ invoice_data.author.phoneNumber }}</b></p>
                      <p>E-MAIL : <b>{{ invoice_data.author.email }}</b></p>
                    </div>
                 </div>
            </div>
  
            <!-- INVOICE CONTENT -->
            <div class="p-base">
              <vs-row class="w-full" >
                <vs-col vs-offset="4"
                 v-tooltip="'col - 12'" 
                 vs-type="flex" vs-justify="center"
                 vs-align="center" 
                 vs-w="4">
                <h1><b>TRAITEMENT(S)</b></h1> 
                </vs-col>
              </vs-row>
                <!-- INVOICE TASKS TABLE -->
                <vs-table hoverFlat class="mt-4" :data="invoice_data.actes">
                    <!-- HEADER -->
                    <template slot="thead">
                      <vs-th class="pointer-events-none">TRAITEMENT(S)</vs-th>
                      <vs-th class="pointer-events-none">Prix</vs-th>
                    </template>
  
                    <!-- DATA -->
                    <template slot-scope="{data}">
                        <vs-tr v-for="(tr, index) in data" :key="index">
                          <vs-td :data="data[index].name">{{ data[index].name }}</vs-td>
                          <vs-td :data="data[index].price">{{data[index].price}}{{' '}}FCFA</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <vs-table hoverFlat :data="invoice_data.actes" class="w-1/2 ml-auto mt-4">
                    <vs-tr>
                        <vs-th class="pointer-events-none">TOTAL</vs-th>
                        <vs-td>{{ invoice_data.totalFees }} FCFA</vs-td>
                    </vs-tr>
                </vs-table>
            </div>
  
            <!-- INVOICE FOOTER 
            <div class="invoice__footer text-right p-base">
                <p >POLYCLINIQUE INTERNATIONALE SAINT JOSEPH DE LOME</p>
                <p>
                    <span >LOCALISATION: <span class="font-semibold">Lomé-Togo Bd. du Haho - Quartier Tokoin Hédzanawoé</span></span>
                    <span > BP : <span class="font-semibold"> 10039 Lomé </span></span>
                </p>
                <p >
                  <span>REF : <span class="font-semibold"> ETG3638</span></span>
                  <span> TEL : <span class="font-semibold"> +228 22 26 72 32</span></span>
                  <span> SITE WEB: <span class="font-semibold">  http://www.stjoclinic-togo.com</span></span>

                </p>

            </div>
          -->
        </vx-card>
  
        <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center">
           
          </div>
          <div class="flex items-center">
            <!--<vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button>-->
            <vs-button color="success" type="filled" class="mb-base mt-6" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Imprimer</vs-button>
          </div>
        </div>
  
    </div>
  </template>
  
<script>
import router from '@/router'
export default{
  data () {
    return {
      exist_prescription: '',
      invoice_data:[],
      roleObject: {
        admin: 'Administrateur',
        dentist: 'Dentiste',
        generalist:'Généraliste',
        secretary: 'Sécrétaire',
        accountant: 'Comptable'
      },
      genderObject: {
        male: 'Masculin',
        female: 'Féminin'
          
      }
    }
  },
  computed: {
  },
  methods: {
    printInvoice () {
      document.title = 'TRAITEMENT'
      window.print()
    }
  },
    
  mounted () {
    if (this.exist_prescription !== '') {
      this.$emit('setAppClasses', 'invoice-page')
      this.invoice_data = this.$store.state.prescription.treatmentinvoice
    } else {
      router.push(router.currentRoute.query.to || 'treatments')
  
    }
     
  },
  
  created () {
    this.exist_prescription = this.$store.state.prescription.prescriptionivoice   
  }
  
}
</script>
  
  <style lang="scss">
  @media print {
  .invoice-page {
    * {
      visibility: hidden;
    }
  
    #content-area {
      margin: 0 !important;
    }
  
    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }
  
    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
  }
  
  @page {
  size: auto;
  }
  @media print {
  
  .invoice__footer {
    display: block;
    position: fixed;
    text-align: end;
    bottom: 0;
    height: 35mm;
  }

 
}
  </style>
  